<template>
    <div class="product">
        <div class="banner">
            <Banner :type="'PRODUCT'" :height="500"></Banner>
        </div>
        <div class="content">
            <div class="title">
                <span></span>
                产品服务
            </div>
            <div class="section">

                <div class="menu fl">
                    <div class="item" :class="{'active':data.tabIndex==index}" :key="index" v-for="(item, index) in data.list" @click="change_tab_item(index)">{{item.title}}</div>
                </div>

                <div class="text fr" 
                    :key="index" 
                    v-for="(item, index) in data.list"
                >
                <template v-if="data.tabIndex==index" >
                    <img :src="item.banner" alt="">
                    <p>{{item.titleDesc}}</p>
                </template>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" src="./product.scss" scoped></style>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import Banner from "../../components/banner";

export default {
    setup(props) {

        let { proxy } = getCurrentInstance();
        const data = reactive({
            list:[],
            tabIndex:0
        });

        const change_tab_item = (index)=>{
            data.tabIndex = index;
        }

        onMounted(()=>{
            proxy.$axios.get("/product/get")
            .then((res)=>{
                data.list = res.data;
            })
        })

        return{
            data,
            change_tab_item
        }
    },
    components: {
        Banner
    }
};
</script>
